import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import RecognitionForDonation from "../components/RecognitionForDonation";
// import MixerAreaTwo from "../components/MixerAreaTwo";
// import TeamArea from "../components/TeamArea";
// import FaqArea from "../components/FaqArea";
// import ServiceArea from "../components/ServiceArea";
// import ClientsLogoTwo from "../components/ClientsLogoTwo";
import Footer from "../components/Footer";

const AboutPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | Recognition For Donation">
            <NavOne />
            <PageHeader title="Recognition For Donation" />
            <RecognitionForDonation />
            <Footer />
        </Layout>
    );
};

export default AboutPage;
