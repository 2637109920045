import React from "react";
import { Link } from "gatsby";
import sectionIcon from "../images/section-icon.png";
const WhyJci = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <br />
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">Recognition For Donation</h2>
                                {/* <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    Your contributions award you to be the part of Life Member Council for life-time and gives you other recognitions* including but not limited to
                                </p>
                                <p className="section__desc">
                                    <ul className="form__list">
                                        <li>
                                            <i className="donate-content-list fa fa-check"></i> Attractive Pin
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i> Certificate
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i> Foundation Card
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Plaque
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            ID card
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Conferring the title during the Zone Conferences or National Conference of JCI India
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Felicitation honour for the contributors during the esteem events
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Photo will be displayed at Wall of Honour of JCI India's National Head Quarter
                                        </li>
                                    </ul>
                                </p>
                                <p className="section__desc">
                                    * The recognitions differ for each title. Kindly refer to the individual pages of each title to know more.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div className="row">
                    <div className="col-lg-6 text-center">
                        <div className="header-btn ml-auto">
                            <Link to="/donate" className="theme-btn">
                                donate now
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center">
                        <div className="header-btn ml-auto">
                            <Link to="/all-titles" className="theme-btn">
                                All Titles
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyJci;
